<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">CONDITIONS GENERALES<br>D'UTILISATION ET DE VENTE</h1>      
      <p class="maintext"></p>
      </section>
    <section class="legals" id="anchor">
      <h2 class="legals__title">CONDITIONS GENERALES D'UTILISATION</h2>
      <h3 class="legals__subtitle">Veuillez lire attentivement les présentes Conditions Générales d’Utilisation.</h3>
      <p class="legals__text">Ce site (le «Site») est édité par LM Créations Numériques, Entreprise Individuelle à Responsabilité Limitée immatriculée avec le numéro de SIRET 89748949800013 dont le siège est Lieu Dit Paussis 82500 Esparsac (ci-après « LM Créations Numériques», « nous », « notre », « nos »). La responsable de la publication du Site est Madame Laurie Montagner.<br>
      Le Site est hébergé par la société OVH SAS dont le siège social est situé au 2 rue Kellermann 59100 Roubaix, France.<br>
      Le Site fournit à ses utilisateurs un accès aux contenus relatifs aux services proposés par LM Créations Numériques et à ses réalisations et notamment des contenus vidéos, des images, des textes, des données et d’autres contenus similaires. L’ensemble des contenus et services fournis sur le Site sont ci-après désignés par le terme « Services ».<br>
      Votre utilisation du Site est régie par les présentes Conditions Générales d’Utilisation indépendamment du moyen par lequel vous accédez au Site (par Internet, par l'intermédiaire d’un réseau de type « Wireless Access Protocole » (communément dénommé "WAP"), à travers un réseau mobile, ou via d’autres types de réseaux.
      Les présentes Conditions Générales d’Utilisation régissent votre relation avec le Site.<br>
      Le Site peut inclure des applications de tiers (telles que définis à la section 5 ci-dessous). Votre utilisation desdites applications est régie par des conditions supplémentaires qui ne sont pas comprises dans les présentes Conditions Générales d’Utilisation et qui sont mises à disposition par leurs fournisseurs.
      </p>
      <h3 class="legals__subtitle">Article 1 : Acceptation des conditions</h3>
      <p class="legals__text">L’accès au Site et l’utilisation de ce dernier implique votre acceptation sans réserve des présentes Conditions Générales d’Utilisation. Nous nous réservons le droit d’apporter des modifications aux présentes Conditions Générales d’Utilisation, modifications qui prendront effet dès leur mise en ligne sur le Site.<br>
      Une fois ces modifications mises en ligne sur le Site, votre utilisation du Site emporte acceptation des Conditions Générales d’Utilisation modifiées.
      </p>
      <h3 class="legals__subtitle">Article 2 : Territoire</h3>
      <p class="legals__text">Le Site peut être indisponible dans certains pays.<br>
      Vous êtes responsable de votre utilisation du Site. Vous vous engagez notamment à respecter l’ensemble des lois locales et des règles d’usage. Nous nous réservons le droit de restreindre l’accès au site, en tout ou en partie, à toute personne, zone géographique ou juridiction à tout moment et à notre seule discrétion.
      </p>
      <h3 class="legals__subtitle">Article 3 : Accès au site</h3>
      <p class="legals__text">Le site lmcreationsnumeriques.fr permet d’accéder gratuitement aux services suivants :
        <ul>
          <li class="legals__list">Accès aux contenus relatifs aux services proposés par LM Créations Numériques</li>
          <li class="legals__list">Différentes réalisations de LM Créations Numériques</li>
          <li class="legals__list">Des moyens de contact</li>
        </ul>
      Le site est accessible gratuitement depuis n’importe où par tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…) sont à la charge de l’utilisateur.
      Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.
      </p>
      <h3 class="legals__subtitle">Article 4 : Règles d’utilisation</h3>
      <p class="legals__text">En accédant au Site ou en utilisant celui-ci, vous vous engagez à respecter l’ensemble des législations en vigueur ainsi que les droits des tiers. Vous vous engagez notamment à respecter les règles suivantes (« Les règles d’usage »):<br>
        Vous vous engager à ne pas :<br>
        <ul>
          <li class="legals__list">Utiliser le Site dans un but contraire aux législations en vigueur.</li>
          <li class="legals__list">Entraver le fonctionnement du Site et/ou l’accès à celui-ci notamment par la modification des paramètres du serveur et/ou du réseau ou de manière générale, par la violation des procédures et des règles d’usage relatives aux réseaux.</li>
          <li class="legals__list">Restreindre ou empêcher un tiers d’utiliser ou de jouir du Site.</li>
          <li class="legals__list">Reproduire, dupliquer, copier, vendre, revendre ou exploiter à des fins commerciales, tout ou une partie du Site.</li>
          <li class="legals__list">Utiliser le site pour promouvoir des produits et services sans obtenir notre accord préalable.</li>
          <li class="legals__list">Modifier, adapter, traduire, décompiler ou désassembler tout ou partie du Site en dehors des cas expressément autorisé par la loi applicable.</li>
          <li class="legals__list">Supprimer les mentions relatives aux droits de la propriété intellectuelle ou tout autre élément du Site (texte, image, son ou autres).</li>
          <li class="legals__list">Dupliquer ou reproduire sous forme de « framing » tout ou partie du Site.</li>
          <li class="legals__list">Créer une base de données dans le but de télécharger et de sauvegarder systématiquement tout ou partie du contenu du Site.</li>
          <li class="legals__list">Utiliser un robot de recherche « spider » ou un aspirateur de site ou un autre dispositif manuel ou automatique permettant d’extraire, indexer les données du Site ou de reproduire ou détourner la structure de navigation ou la présentation du Site, sans notre accord préalable.</li>
        </ul>
        Nous nous réservons le droit de mettre fin à votre utilisation en cas de violation des présentes conditions.
        </p>
        <h3 class="legals__subtitle">Article 5 : Droits de propriété intellectuelle</h3>
      <p class="legals__text">Nous sommes propriétaires de l’ensemble des contenus que nous mettons à disposition des utilisateurs sur le Site. Ces contenus sont protégés par le droit d’auteur, le droit des marques, le droit des brevets ou toute autre législation en vigueur.<br>
      Sauf autorisation expresse et préalable de notre part, vous vous engagez à ne pas reproduire, modifier, louer, emprunter, vendre, distribuer ou créer d'œuvres dérivées basées sur tout ou partie du Site ou tout autre contenu mis à disposition par l'intermédiaire du Site.<br>
      En outre, le Site peut contenir des marques et des noms commerciaux ou d’autres éléments protégés appartenant à des tiers. Ces éléments protégés restent la propriété de leurs titulaires de droits respectifs. Tous les brevets, marques, noms commerciaux et / ou autres éléments de propriété intellectuelle sont protégés par les législations en vigueur. Sauf autorisation expresse et préalable, vous vous engagez à ne pas reproduire, modifier, louer, emprunter, vendre, distribuer ou créer d'œuvres dérivées basées sur tout ou partie du Site ou sur tout autre élément mis à disposition par l'intermédiaire du site.<br>
      Aucune mention sur le site ne doit être interprétée comme accordant une licence ou un droit d'usage sur un nom commercial, une marque ou tout autre élément protégé sans l’accord écrit et préalable du titulaire des droits.<br>
      Veuillez noter que l’utilisation non autorisée des produits et services, incluant sans limitation, l’utilisation du logiciel du service peut engager votre responsabilité civile et pénale (incluant, sans limitation, une possible condamnation à payer des dommages-intérêts).
      </p>
      <h3 class="legals__subtitle">Article 6 : Applications de tiers</h3>
      <p class="legals__text">Le site peut contenir des logiciels et des services (ou des liens s'y rapportant) édités par des tiers et mis à disposition par nos fournisseurs (ci-après « Application de tiers »).<br>
      Dans la mesure où nous ne maitrisons pas les Application de tiers, nous ne pouvons être tenus responsable d’une Application de tiers, incluant, sans limitation, l'exécution, l'exactitude, l'intégrité, la qualité, la légalité, l'utilité, ou la sécurité, ou les droits de propriété intellectuelle concernant l’Application de tiers ou son utilisation.<br>
      Nous n'avons aucune obligation de surveiller l’Application de tiers et nous nous réservons le droit de supprimer ou limiter l'accès à tout ou partie de l’Application de tiers à partir du Site et ce, à notre seule discrétion.<br>
      La disponibilité d’une Application de tiers sur le Site n’implique pas notre approbation de celle-ci ni notre affiliation à son fournisseur. En outre, votre utilisation de l’Application de tiers peut être soumise à des conditions supplémentaires (telles que les conditions prévues par le fournisseur de l’Application de tiers) qui ne sont pas inclus dans les présentes conditions ni dans la Politique de Confidentialité. Les présentes conditions n’établissent aucune relation légale entre vous et le fournisseur de l’Application de tiers. Aucune mention dans les présentes conditions ne peut être interprétée comme une garantie de notre part à l’égard de toute Application de tiers.
      </p>
      <h3 class="legals__subtitle">Article 7 : Contenu de tiers</h3>
      <p class="legals__text">Le Site peut intégrer certaines fonctionnalités permettant l’accès à des contenus fournis par de tiers (« Contenu de tiers »). Dans la mesure où nous ne maitrisons pas le Contenu de tiers, vous acceptez que nous ne puissions être tenus responsable du Contenu de tiers, incluant, sans limitation, l'exécution, l'exactitude, l'intégrité, la qualité, la légalité, l'utilité, ou la sécurité, ou les droits de propriété intellectuelle concernant le Contenu de tiers ou son utilisation.<br>
      Nous n'avons aucune obligation de surveiller le Contenu de tiers et nous nous réservons le droit de supprimer ou limiter l'accès à tout ou partie du Contenu de tiers à partir du Site et ce, à notre seule discrétion.<br>
      En outre, votre utilisation du Contenu de tiers peut être soumise à des conditions supplémentaires (telles que les conditions prévues par le fournisseur du Contenu de tiers) qui ne sont pas incluses dans les présentes conditions ni dans la Politique de Confidentialité.<br>
      Les présentes conditions n’établissent aucune relation légale entre vous et le fournisseur du Contenu de tiers. Aucune mention dans les présentes conditions ne peut être interprétée comme une garantie de notre à l’égard du Contenu de tiers.
      </p>
       <h3 class="legals__subtitle">Article 8 : Liens et flux</h3>
      <p class="legals__text">Le site peut contenir des liens vers des sites externes et/ou des flux d’information en provenance d’autres sites. Des sites externes peuvent contenir des liens vers le Site avec ou sans notre consentement. Nous nous réservons le droit de bloquer tout lien vers le Site ou à partir de celui-ci.<br>
      Votre utilisation d’un site web externe est effectuée sous votre entière responsabilité. Nous ne pouvons pas être tenus responsables pour une telle utilisation.
      </p>
       <h3 class="legals__subtitle">Article 9 : Limitation de responsabilité</h3>
      <p class="legals__text">Nous n’apportons aucune garantie sur les contenus de tiers et les applications de tiers présents sur le site.<br>
      En outre, nous ne pouvons vous garantir la compatibilité d’une application avec le site et vous invitons à vérifier au préalable cette compatibilité.<br>
      Vous demeurez seul responsable de l’obtention du logiciel, matériel, et/ou tout autre élément (y compris la connexion à l’internet) nécessaire à l’utilisation du site vous assurer que le logiciel, matériel et service que vous utilisez est compatible avec le site.<br>
      Vous êtes seul responsable de l’évaluation des risques liés à l’utilisation du site incluant, sans limitation, l'exactitude, l'intégralité ou l'utilité des applications de tiers, des contenus de tiers, et de l’ensemble des contenus disponibles sur le site.<br>
      Nous faisons notre meilleur effort pour maintenir la sécurité du Site. Toutefois, nous ne pouvons pas garantir la sécurité du Site, du Contenu de tiers ou des Applications de tiers. En outre, nous ne garantissons pas le fonctionnement ininterrompu du Site ou des Applications de tiers.<br>
      Le Site et/ou les applications des tiers peuvent faire l’objet d’une modification non autorisée effectuée par des tiers.</p>
       <h3 class="legals__subtitle">Article 10 : Réclamations et demandes d’information</h3>
      <p class="legals__text">Si vous avez une question ou une réclamation concernant le Site, nous vous invitons à nous contacter par e-mail à l'adresse contact@lmcreationsnumeriques.fr. Les communications par email n’étant pas sécurisées, nous vous invitons en conséquence à ne pas inclure d’information confidentielle dans votre email.</p>
       <h3 class="legals__subtitle">Article 11 : Contact</h3>
      <p class="legals__text">Si vous avez des questions concernant l’interprétation et/ou l'application des présentes conditions, nous vous invitons à nous contacter à l’adresse contact@lmcreationsnumeriques.fr. Les communications par email n’étant pas sécurisées, nous vous invitons en conséquence à ne pas mentionner d’information confidentielle dans vos emails.</p>
      <h3 class="legals__subtitle">Article 12 : Durée du contrat</h3>
      <p class="legals__text">Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur.</p>
      <h3 class="legals__subtitle">Article 13 : loi applicable et attribution de juridiction</h3>
      <p class="legals__text">La loi Française sera seule applicable aux présentes CGU.</p>
      <h2 class="legals__title">CONDITIONS GENERALES DE VENTE</h2>
      <h3 class="legals__subtitle">ARTICLE 1 – DOMAINE D’APPLICATION</h3>
      <p class="legals__text">Nos prestations de services sont soumises aux présentes conditions générales qui prévalent sur toute autre stipulation de votre part. Par ailleurs, les présentes conditions générales ont pour objet de vous informer sur les conditions avec lesquelles nous procédons à la création et conception de sites internet ainsi qu’à la réalisation de vidéos, la création de contenu et les prestations annexes. Le simple fait d’accepter un devis par mail, ou par retour d’un devis signé, fera office de commande et vaudra consentement irrévocable et définitif de votre part sur toutes les dispositions figurant dans les conditions générales. En acceptant un devis LM Créations Numériques, vous reconnaissez avoir eu parfaite connaissance des conditions générales et vous renoncez de ce fait à vous prévaloir de tout document contradictoire, et notamment de vos propres conditions générales. Cette confirmation ainsi que l’ensemble des données que nous aurons enregistrées constitueront la preuve de nos transactions. Nous pourrons modifier les présentes conditions générales à tout moment sous réserve de faire apparaître ces modifications sur notre site. Les clients LM Créations Numériques, dont les projets sont en cours de réalisation, seront prévenus par mail de la modification de nos CGV. Chacune des clauses de ce contrat se suffit, l’invalidation de l’une n’ayant aucun effet sur les autres.</p>
      <h3 class="legals__subtitle">ARTICLE 2 - DÉFINITIONS</h3>
      <p class="legals__text">Charte graphique : ensemble des éléments graphiques, couleurs, formes, textures, mise en page qui constitue la base graphique pour n’importe quel support de communication (site internet, plaquette, affiches…)<br><br>
      Internet : Réseau de plusieurs serveurs reliés entre eux et dont la localisation se situe en divers lieux géographiques à travers le monde.<br><br>
      Site web : un site web est l’ensemble des pages et le cas échéant des logiciels qui les génèrent automatiquement, hébergé sur un même serveur et qui constitue le service fourni aux utilisateurs.<br><br>
      Vidéo : une vidéo est une production audiovisuelle associant de l’image et du son, destinée à être diffusée sur différents supports à des fins multiples<br><br>
      Film institutionnel : un film ou une vidéo institutionnelle est un outil de communication institutionnelle qui vise à présenter une marque ou une entreprise à différents publics<br><br>
      Création de contenu : pratique qui consiste à concevoir des supports (photos, vidéos, infographies...) pour être partagés sur les différents médias en ligne. Ils permettent de fidéliser sa clientèle en la mettant en valeur et/ou en la captivant, tout en se donner l'opportunité d'atteindre de nouveaux clients<br><br>
      Abonnement : Contrat par lequel on acquiert le bénéfice d'un service régulier moyennant un prix forfaitaire pour une durée déterminée.<br><br>
      CMS : Content Management Systems, famille de logiciels destinés à la conception et à la mise à jour dynamique de sites Web ou d'applications multimédia (exemple : Wordpress, Prestashop)<br><br>
      Contrat : Les présentes CGV
      </p>
      <h3 class="legals__subtitle">ARTICLE 3 – OBJET DU CONTRAT</h3>
      <p class="legals__text">Le présent contrat a pour objet de définir les conditions suivant lesquelles nous réalisons votre site web, vidéo, film institutionnel ou création de contenu ; et les conditions suivant lesquelles vous rémunérez ces prestations. L’acceptation des présentes conditions générales dans le cadre d’un abonnement à la création de contenu implique votre acceptation des conditions générales de vente spécifiques aux abonnements à la création de contenu.</p>
      <h3 class="legals__subtitle">ARTICLE 4 – TARIFS DES SERVICES</h3>
      <p class="legals__text">Les prix indiqués sont exprimés en euros hors taxes (HT). Les devis sont établis en fonction du besoin de chaque client. Le tarif forfaitaire indiqué tient compte du temps de travail et des technologies utilisées, estimé par LM Créations Numériques. Toute demande supplémentaire faite par le client après validation du devis (ajout de page, de fonctionnalité ou autre outil informatique), fera l’objet d’un avenant au devis. Toutes les commandes quelle que soit leur origine sont payables en euros. Les prix de nos services peuvent être modifiés à tout moment par la survenance d’événements extérieurs. Cette modification sera mise en ligne et portée à votre connaissance avant toute commande.<br>
      LM Créations Numériques se réserve le droit de réajuster ses tarifs lors du renouvellement des abonnements et notamment en fonction des éventuelles variations imposées par les organismes d’enregistrement, des parités monétaires ou des taxes supplémentaires, ou de toute modification de politique tarifaire pratiquée par LM Créations Numériques ou par ses prestataires.
      </p>
      <h3 class="legals__subtitle">ARTICLE 5 – COMMANDES</h3>
      <p class="legals__text">Les ventes de prestations de services ne sont parfaites qu’après acceptation du devis par le client de la proposition commerciale élaborée par LM Créations Numériques sur la base des besoins exprimés par le client, matérialisée par la mention « Bon pour accord » et la signature. Les éventuelles modifications de la commande demandées par le client ne seront prises en compte, dans la limite des possibilités de LM Créations Numériques et à sa seule discrétion, qu’après signature d’un avenant au devis spécifique et ajustement éventuel du prix. En cas d'annulation de la commande par le client après son acceptation, pour quelque raison que ce soit, hormis la force majeure, l'éventuel acompte versé à la commande sera de plein droit acquis à LM Créations Numériques et ne pourra donner lieu à un quelconque remboursement. A défaut d’acompte, une somme correspondant à 30% du montant HT de la facture sera acquise à LM Créations Numériques, à titre de dommages et intérêts.
      </p>
      <h3 class="legals__subtitle">ARTICLE 6 – MODALITÉ DE PAIEMENT</h3>
      <p class="legals__text">Les modalités de paiement sont inscrites sur le devis et reprises sur la facture. Le paiement doit être effectué dans les 10 jours ouvrés suivant la réception.</p>
      <h3 class="legals__subtitle">6.1 RETARDS DE PAIEMENTS</h3>
      <p class="legals__text">En cas de retard de paiement, des pénalités de 15% par mois à partir de la date d’émission de la facture s’appliqueront au montant restant dû. Une indemnité forfaitaire de 40€ (quatre-vingt euros) s’appliquera en sus des pénalités de retard. Toutes les créations réalisées par la société LM Créations Numériques restent la propriété de LM Créations Numériques jusqu’au règlement complet, par conséquent en cas de non règlement, LM Créations Numériques est en droit de suspendre l’activité d’un site ou d’une prestation, revendre une création ou toutes autres actions qui permettraient à LM Créations Numériques de se dédommager. En cas de non-paiement dans un délais de 6 mois, LM Créations Numériques se réserve le droit d’engager des poursuites judiciaires au frais du client.</p>
      <h3 class="legals__subtitle">ARTICLE 7 – MODALITÉS DE LIVRAISON</h3>
      <p class="legals__text">Une date de mise en ligne pour un site internet est fixée en fonction du temps de réalisation estimé. Pour les réalisations de vidéos et films institutionnels, LM Créations Numériques donnera une estimation de la date de rendu de la production. Dans le cadre de l’abonnement à la création de contenu, une date mensuelle sera fixée pour les différents productions commandées.<br>
      LM Créations Numériques s’engage à respecter ces délais si et seulement si tous les éléments devant être fournis par le client (textes numérisés, images, validation charte graphique, renseignements et tout élément émanant du client) sont fournis dans les temps et les conditions définies par LM Créations Numériques.<br>
      LM Créations Numériques ne pourra pas être tenu responsable en cas de retard de livraison dû à des évènements exceptionnels (décès, maladie, panne informatique ou logicielle). Mais en cas d’imprévu ou de dépassement de délai, LM Créations Numériques s’engage à finaliser en priorité le projet en retard.
      </p>
      <h3 class="legals__subtitle">7.1 TESTS ET DEBOGAGE</h3>
      <p class="legals__text">Lors de la réalisation d’un site, LM Créations Numériques testera les fonctionnalités principales. Dans le cas d’un site avec CMS, la majorité des tests resteront à la charge du client sauf mention contraire stipulé sur le devis. Si des fonctionnalités n’ont pas été testées et s’avèrent non fonctionnelles après la période de garantie, LM Créations Numériques n’en sera pas tenu pour responsable. Il est donc conseillé de tester le maximum de fonctionnalités avant la fin de période de garantie.</p>
      <h3 class="legals__subtitle">7.2 PERIODE DE GARANTIE</h3>
      <p class="legals__text">Lors de la livraison du site, un document vous sera alors envoyé attestant la fin de la réalisation de votre site, celui-ci déclenchera automatiquement la période de garantie d’une durée de 3 mois. Si le document ne vous est pas transmis, la date du dernier règlement principal fera foi. Pendant cette période de garantie, LM Créations Numériques s’engage à corriger ou résoudre tous problèmes ou bugs, issus uniquement de la conception du site.<br>
      Toutes modifications du code source par un tiers ou par le propriétaire du site annulent automatiquement la période de garantie. Passé cette période de garantie toutes modifications seront facturées. Les délais d’interventions en cas de problèmes sont les suivants :
      <ul>
        <li class="legals__list">Problèmes urgents : intervention sous 3 jours ouvrés + délai de correction aléatoire</li>
        <li class="legals__list">Problèmes standards : intervention sous 8 jours ouvrés + délai de correction aléatoire</li>
        <li class="legals__list">Problèmes mineurs : intervention sous 20 jours ouvrés + délai de correction aléatoire</li>
      </ul>
      </p>
      <h3 class="legals__subtitle">7.3 CONDITIONS PARTICULIERES DE GARANTIE POUR LES SITES REALISES A L’AIDE DE CMS</h3>
      <p class="legals__text">Il est convenu que l’utilisateur d’un CMS ne doit pas mettre à jour un module, la version du CMS ou toutes autres extensions, cela pouvant entrainer des dysfonctionnements, problèmes affichage ou autres erreurs.<br>
        Toutes mises à jour d’un module, de la version du CMS ou d’une extension sans l’accord express et écrit de LM Créations Numériques engendra l’annulation de la garantie. Toutes modifications seront facturées.
      </p>
      <h3 class="legals__subtitle">ARTICLE 8 – MODALITÉS DE VALIDATION</h3>
      <p class="legals__text">Pour un site internet, une proposition de maquette et de charte graphique est présentée au client pour validation. Le client s’engage à valider, ou non, la maquette et la charte graphique en confirmant par mail ou par écrit dans un délai de 3 jours ouvrés. LM Créations Numériques lui accorde toutefois la possibilité de demander des modifications à cette maquette et charte graphique : les modifications demandées doivent être précises, concises, claires et définitives. La maquette modifiée lui sera présentée une deuxième fois pour validation, et une troisième fois si besoin pour certains détails. Passé cette procédure, LM Créations Numériques se réserve le droit de faire un avenant au devis pour dépassement de temps de création prévu.<br>
      Une fois le site internet terminé, il présenté au client pour validation définitive. Le client s’engage à valider le site en confirmant par mail ou par écrit dans un délai de 3 jours ouvrés. LM Créations Numériques lui accorde toutefois la possibilité de demander des modifications au site : les modifications demandées doivent être précises, concises, claires et définitives. Le site modifié lui sera présenté une deuxième fois pour validation. Passé cette procédure, LM Créations Numériques se réserve le droit de faire un avenant au devis pour dépassement de temps de création prévu.<br>
      Pour une réalisation vidéo, une première version est présentée au client. Le client s’engage à valider la vidéo en confirmant par mail ou par écrit dans un délai de 3 jours ouvrés. LM Créations Numériques lui accorde toutefois la possibilité de demander des modifications à cette vidéo : les modifications demandées doivent être précises, concises, claires et définitives. La vidéo modifiée lui sera présentée une deuxième fois pour validation. Passé cette procédure, LM Créations Numériques se réserve le droit de faire un avenant au devis pour dépassement de temps de création prévu.
      </p>
       <h3 class="legals__subtitle">ARTICLE 9 – CONTENU TEXTES, IMAGES ET DROITS</h3>
      <p class="legals__text">Le client s’engage à fournir à LM Créations Numériques des photos dont il a les droits à l’image. LM Créations Numériques ne sera en aucun cas responsable ni des photos fournies par le client, ni des textes rédigés par celui-ci, autant sur les sites internet que sur tout support de communication réalisé par nos services pour nos clients. LM Créations Numériques se réserve toutefois le droit de ne pas publier ou ne pas utiliser des photos ou des textes jugés douteux ou non conformes à l’éthique et la déontologie. LM Créations Numériques n’est pas chargé de la rédaction des textes du client, ni de la correction orthographique ou de syntaxe, sauf mention contraire au devis. Toutes les images utilisées par LM Créations Numériques mais non fournies par le client et non prises par LM Créations Numériques, sont libres de droit. LM Créations Numériques acquiert ces images avec copyright sur des sites spécialisés.
      </p>
       <h3 class="legals__subtitle">ARTICLE 10 – LIMITATIONS DE RESPONSABILITES </h3>
      <p class="legals__text">Nous nous engageons à tout mettre en œuvre pour assurer la permanence, la continuité et la qualité des services que nous proposons. Dans le cas où LM Créations Numériques enregistrerait un nom de domaine de la part du client, LM Créations Numériques ne pourra en aucun cas être tenu responsable de l’éventuel refus d’enregistrement d’un nom de domaine par l’organisme chargé de son administration et ce même si ce domaine apparaît disponible. Si l’enregistrement a été réglé par le client, et qu’il est impossible à LM Créations Numériques de procéder à son dépôt, LM Créations Numériques ne sera tenu que du remboursement des sommes versées par le client au titre de l’enregistrement du nom de domaine, aucun dédommagement ne pourra être réclamé par le client. Nous ne garantissons pas que l’enregistrement du nom de domaine ait pour effet d’éviter d’éventuelles contestations relatives au nom de domaines réservé. En cas de panne imputée à l’organisme d’hébergement, LM Créations Numériques ne pourra pas être responsable des dommages causés par l’indisponibilité du site du client. Vous utiliserez le service conformément aux instructions de fonctionnement que nous vous communiquerons, et vous serez le seul responsable des conséquences dommageables d’une utilisation non conforme. Les services proposés sont conformes à la législation française en vigueur. Notre responsabilité ne saurait être engagée en cas de non-respect de la législation de votre pays. Vous reconnaissez avoir reçu les conditions, mises en garde et informations nécessaires. <br>
      Dans le cas où vous possédez déjà un nom de domaine, vous vous engagez et garantissez que toutes les informations que vous avez fournies en connexion ou liées à l’enregistrement sont complètes et exactes. <br>
      La résiliation du contrat d’hébergement pourra se faire directement par le client envers l’organisme d’hébergement, LM Créations Numériques n’en est pas responsable. LM Créations Numériques pourra cependant, sur devis, effectuer la prestation de clôture de vos contrats d’hébergement.
      </p>
       <h3 class="legals__subtitle">ARTICLE 11 - FORCES MAJEURES</h3>
      <p class="legals__text">Aucune des parties ne sera tenue responsable vis-à-vis de la non-exécution ou des retards dans l’exécution d’une obligation du présent contrat qui seraient dus à la survenance d’un cas de force majeure habituellement reconnu par la jurisprudence et les tribunaux français. Le cas de force majeure suspend les obligations nées du présent contrat pendant toute la durée de son existence.</p>
       <h3 class="legals__subtitle">ARTICLE 12 - MODALITÉS SPÉCIFIQUES AUX ABONNEMENTS</h3>
      <p class="legals__text">Nos abonnements vous engagent à respecter les délais de paiement et la période d’engagement précisée sur le devis. Ils nous engagent en retour à respecter la livraison des services précisés sur le devis. Nos abonnements sont reconductibles tacitement selon la durée indiquée sur le devis. En cas de demande de résiliation, la demande doit être effectuée 1 mois avant la date d’anniversaire du dit contrat. En cas de résiliation anticipée, les échéances restantes seront dues. En cas de renouvellement, les taxes et le tarif applicable seront ceux en vigueur au jour du renouvellement. <br>
      A défaut de l’entier paiement du prix du renouvellement fixé dans le tarif, LM Créations Numériques ne pourra effectuer le renouvellement demandé par le Client. LM Créations Numériques entreprendra l’arrêt définitif de ses missions à la date anniversaire du renouvellement, le Client recevra un e-mail de notification pour l’informer de l’arrêt de la mission pour défaut de paiement. Afin d’éviter toute rupture de continuité de la prestation, il appartient au Client de solliciter le renouvellement avec un délai suffisant de sorte que le paiement soit effectivement reçu par LM Créations Numériques avant expiration de l’abonnement. Le Client est seul responsable du paiement de l’ensemble des sommes dues au titre du contrat d’abonnement à la création de contenu de LM Créations Numériques.<br>
      La résiliation du contrat peut intervenir en cas d’inexécution par l’une ou l’autre des parties, de l’une quelconque des obligations stipulées dans le présent contrat. Celui-ci sera résilié de plein droit quinze jours après l’envoi d’une mise en demeure restée infructueuse adressée à la partie défaillante par lettre recommandée avec avis de réception.
      </p>
         <h3 class="legals__subtitle">12.1 MAINTENANCE</h3>
      <p class="legals__text">Notre abonnement à la maintenance de votre site nous engage à effectuer les services suivants :
        <ul>
        <li class="legals__list">Résolutions de problèmes, dans les mêmes délais que lors de la période de garantie :
          <ul>
            <li class="legals__list">Problèmes urgents : intervention sous 3 jours ouvrés + délai de correction aléatoire</li>
            <li class="legals__list">Problèmes standards : intervention sous 8 jours ouvrés + délai de correction aléatoire</li>
            <li class="legals__list">Problèmes mineurs : intervention sous 20 jours ouvrés + délai de correction aléatoire</li>
          </ul>
        <li class="legals__list">Mises à jour des extensions, modules et version des CMS (Wordpress, Prestashop)</li>
        <li class="legals__list">Modifications mineures de votre site (ex : changement d’une adresse, ajout d’un lien en pied de page, mise à jour d’un logo…)</li>
          </ul>        
        </p>
         <h3 class="legals__subtitle">ARTICLE 13 – DÉLAI DE RÉTRACTATION</h3>
      <p class="legals__text">En raison de la nature des prestations de services immatérielles livrées uniquement par internet, téléchargement ou clé USB, dont l’exécution a commencé avant le délai de 7 jours, les parties conviennent qu’aucun droit de rétractation ne pourra être exercé.</p>
      <h3 class="legals__subtitle">ARTICLE 14 – PROPRIÉTÉ INTELLECTUELLE</h3>
      <p class="legals__text">Vous reconnaissez et acceptez que les services et tous logiciels utilisés nécessairement en relation avec nos services puissent contenir des informations confidentielles et protégées par le droit de propriété intellectuelle en vigueur ou toute autre loi. Vous reconnaissez également avoir connaissance que le contenu compris dans les publicités sponsorisées, les informations qui vous sont présentées via le service ou par des annonceurs sont protégées par le droit d’auteur, le droit des marques, le droit des brevets, ou tout autre droit reconnu par la législation en vigueur.</p>
      <h3 class="legals__subtitle">ARTICLE 15 – LICENCES</h3>
      <p class="legals__text">Pour chaque nom de domaine dont le client donne la charge à LM Créations Numériques de son enregistrement, vous nous consentez une licence exclusive de son utilisation sur internet. Ce droit d’utilisation s’entend comme la possibilité d’administrer le nom de domaine pour votre compte. A ce titre nous nous enregistrons comme contact technique lors du dépôt de votre nom de domaine. Nous n’acquérons en aucune manière la propriété du nom de domaine. Vous nous autorisez également à faire pointer une page HTML sur l’adresse activée de votre nom de domaine afin d’assurer le bon fonctionnement de votre site.</p>
      <h3 class="legals__subtitle">ARTICLE 16 – CONFIDENTIALITÉ</h3>
      <p class="legals__text">Les parties assurent le caractère confidentiel des termes du présent contrat et de toutes informations obtenues dans le cadre de son application. Elles s’engagent en leur nom comme en celui de leur personnel à respecter le secret professionnel au présent contrat et à tous documents confiés. Vous êtes informés que certaines données personnelles sont transmises aux autorités compétentes et pourront être consultées sur le whois. Le whois est un document accessible sur internet auprès des différentes autorités compétentes dans l’enregistrement des noms de domaine ; ce document prouve que le nom de domaine est enregistré et donne différentes informations sur le propriétaire. Pour en savoir plus, consultez notre politique de confidentialité.</p>
      <h3 class="legals__subtitle">ARTICLE 17 – DONNÉES PERSONNELLES</h3>
      <p class="legals__text">Nous nous engageons à protéger vos données personnelles. Toutes les données personnelles qui sont recueillies sont traitées avec la plus stricte confidentialité, conformément à notre politique de protection des données personnelles. Lors des commandes nous vous demandons des informations indispensables pour assurer la qualité des services. Nous ne vendons pas, ne commercialisons pas et ne louons pas ces informations à des tiers. En application de l’article 27 de la loi informatique et libertés du 6 janvier 1978, vous disposez d’un droit d’accès et de rectification sur les données vous concernant. Il vous suffit pour se faire de nous adresser un e-mail à l’adresse suivante : contact@lmcreationsnumeriques.fr. En adhérant à ces conditions générales, vous reconnaissez avoir pris connaissance de notre politique de protection des données personnelles et vous consentez à ce que nous collections et utilisions ces données. Pour en savoir plus, consultez notre politique de confidentialité.</p>
      <h3 class="legals__subtitle">ARTICLE 18 – INTITULÉS ET LIENS LM CREATIONS NUMERIQUES</h3>
      <p class="legals__text">LM Créations Numériques se réserve le droit d’afficher en bas de page de votre site un lien pointant vers sa page d’accueil : lmcreationsnumeriques.fr</p>
      <h3 class="legals__subtitle">ARTICLE 19 – RÉSERVE DE PROPRIÉTÉ</h3>
      <p class="legals__text">Les sites réalisés par LM Créations Numériques demeureront la propriété de LM Créations Numériques jusqu’aux règlements complets de ceux-ci. LM Créations Numériques s’autorise le droit de désactiver l’accès aux sites si les règlements ne sont pas réalisés selon les conditions de règlement conclues lors de la signature du devis.</p>
      <h3 class="legals__subtitle">ARTICLE 20 – LOI APPLICABLE ET ATTRIBUTION DE JURIDICTION</h3>
      <p class="legals__text">La loi Française sera seule applicable au présent Contrat.<br>
      Tout litige entre les parties, relatif à leurs relations contractuelles et notamment à l’interprétation ou l’exécution et la résiliation du présent Contrat, sera soumis au tribunal de commerce du lieu d’élection de domicile du siège social de LM Créations Numériques même en cas d’appel en garantie ou de pluralité de défendeurs.
      </p>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    /************** APPARITION AU SCROLL */
        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">

/*************** HAUT DE PAGE */
.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

/********************* MENTIONS */

.legals {
  width: 70%;
  margin: auto;
  @media (max-width: 768px) {
    width: 90%;
	}
  &__title {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        text-align: left;
        &::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: calc( 50% - 1.5px );
        border-bottom: 20px solid #38e01f;
        z-index: -1;
        }
  }
  &__subtitle {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &__list {
    margin-left: 5%;
  }
}

/********* Apparition au scroll */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

</style>